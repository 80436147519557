/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/
import eleoLogo from 'images/new-logos/new-logo.png'
import { HashLink } from 'react-router-hash-link'
import { T, useTolgee } from '@tolgee/react'
import { getDocsUrl } from 'helpers'
import { PwaInstall } from 'components/pwa-install/button'

const getNavigation = function (pendingLang) {
  return {
    solutions: [
      { name: 'Chat', href: '/features/chat', id: 'eleo-footer-chat' },
      { name: 'Writer', href: '/features/writer', id: 'eleo-footer-writer' },
      { name: 'Advisor', href: '/features/advisor', id: 'eleo-footer-advisor' },
      { name: 'Translator', href: '/features/translator', id: 'eleo-footer-translator' },
      { name: 'Ideas', href: '/features/ideas', id: 'eleo-footer-ideas' },
      { name: 'Images', href: '/features/images', id: 'eleo-footer-images' },
      { name: 'Your Story', href: '/features/your-story', id: 'eleo-footer-your-story' },
      { name: 'Chatbot', href: '/features/chatbot', id: 'eleo-footer-chatbot' },
      {
        name: 'Browser Extension',
        href: '/features/extension',
        id: 'eleo-footer-browser-extension',
      },
    ],

    support: [
      { name: 'Sign up', href: '/signup#', id: 'eleo-footer-sign-up' },
      { name: 'Log in', href: '/signin#', id: 'eleo-footer-log-in' },
      { name: 'Pricing', href: '/#pricing', id: 'eleo-footer-pricing' },
      { name: 'Guides', href: 'https://news.eleo.ai/category/academy/', id: 'eleo-footer-guides' },
      { name: 'Blog', href: 'https://news.eleo.ai', id: 'eleo-footer-blog' },
      { name: 'FAQ', href: '/questions#', id: 'eleo-footer-faq' },
      { name: 'User guide', href: getDocsUrl(pendingLang), id: 'eleo-help-docs' },
    ],
    company: [
      { name: 'About us', href: '/about', id: 'eleo-footer-about-us' },
      { name: 'Partners', href: '/partners', id: 'eleo-footer-partners' },
      { name: 'Contact', href: '/contact', id: 'eleo-footer-contact' },
    ],
    legal: [
      {
        name: 'Terms and Conditions',
        href: '/terms',
        id: 'eleo-footer-terms-and-conditions',
      },
      { name: 'Privacy Policy', href: '/privacy', id: 'eleo-footer-privacy-policy' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=61553029174448',
        icon: (props) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path
              fillRule='evenodd'
              d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
              clipRule='evenodd'
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/eleo_ai/',
        icon: (props) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path
              fillRule='evenodd'
              d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
              clipRule='evenodd'
            />
          </svg>
        ),
      },
      {
        name: 'TikTok',
        href: 'https://www.tiktok.com/@eleoai',
        icon: (props) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path d='M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z' />
          </svg>
        ),
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/eleo-ai',
        icon: (props) => (
          <svg fill='currentColor' viewBox='0 0 310 310' style={{ padding: '3px' }} {...props}>
            <path
              id='XMLID_802_'
              d='M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
      C77.16,101.969,74.922,99.73,72.16,99.73z'
            />
            <path
              id='XMLID_803_'
              d='M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
      c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z'
            />
            <path
              id='XMLID_804_'
              d='M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
      c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
      c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
      C310,145.43,300.549,94.761,230.454,94.761z'
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/EleoAi',
        icon: (props) => (
          <svg
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 256 256'
            {...props}
          >
            <rect width='256' height='256' fill='none' />
            <polygon
              points='48 40 96 40 208 216 160 216 48 40'
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='16'
            />
            <line
              x1='113.88'
              y1='143.53'
              x2='48'
              y2='216'
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='16'
            />
            <line
              x1='208'
              y1='40'
              x2='142.12'
              y2='112.47'
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='16'
            />
          </svg>
        ),
      },

      {
        name: 'YouTube',
        href: 'http://www.youtube.com/@ELEOAI',
        icon: (props) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path
              fillRule='evenodd'
              d='M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z'
              clipRule='evenodd'
            />
          </svg>
        ),
      },
    ],
  }
}

export function Footer() {
  const tolgee = useTolgee(['pendingLanguage'])
  const navigation = getNavigation(tolgee.getPendingLanguage())

  return (
    <footer className='bg-white' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='space-y-8'>
            <img className='h-[35px] w-[120px]' src={eleoLogo} alt='Eleo.ai' />

            {/* <img
              className="h-7"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Company name"
            /> */}
            <p className='w-[250px] text-sm leading-6 text-gray-600'>
              <T keyName='eleo-footer-text'>
                Unleash your productivity and creativity with Eleo.ai
              </T>
            </p>
            <div
              className='inline-grid grid-cols-6 gap-x-6'
              style={{ gridTemplateColumns: 'fit-content(40%)' }}
            >
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className='text-gray-400 hover:text-gray-500'>
                  <span className='sr-only'>{item.name}</span>
                  <item.icon className='h-6 w-6' aria-hidden='true' />
                </a>
              ))}
              <div className='col-span-6 mt-[35px]'>
                <PwaInstall />
              </div>
            </div>
          </div>
          <div className='mt-[35px] grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                  <T keyName='eleo-footer-solutions'>Solutions</T>
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                      >
                        <T keyName={item.id}>{item.name}</T>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-10 md:mt-0'>
                <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                  <T keyName='eleo-footer-support'>Support</T>
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <HashLink
                        to={item.href}
                        className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                      >
                        <T keyName={item.id}>{item.name}</T>
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                  <T keyName='eleo-footer-company'>Company</T>
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                      >
                        <T keyName={item.id}>{item.name}</T>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-10 md:mt-0'>
                <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                  <T keyName='eleo-footer-legal'>Legal</T>
                </h3>
                <ul className='mt-6 space-y-4'>
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                      >
                        <T keyName={item.id}>{item.name}</T>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24'>
          <p className='text-xs leading-5 text-gray-500'>
            &copy; 2023 Eleo PSA, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
