import { T } from '@tolgee/react'
import { RealtimeTranscriber } from 'assemblyai/streaming'
import axios from 'axios'
import { errorToast } from 'helpers'
import { useRef, useState } from 'react'
import * as RecordRTC from 'recordrtc'

export function useTranscription() {
  const realtimeTranscriber = useRef(null)
  const recorder = useRef(null)
  const [isRecording, setIsRecording] = useState(false)
  const [transcript, setTranscript] = useState('')
  const [isLoading, setIsLoading] = useState('')

  const getToken = async () => {
    const res = await axios.get('/api/stt/token')
    if (!res.data.token)
      errorToast(
        <T keyName='eleo-error-stt'>Speech to text is currently unavailable. Try again later.</T>
      )

    return res.data.token
  }

  const startTranscription = async () => {
    setIsLoading(true)
    realtimeTranscriber.current = new RealtimeTranscriber({
      token: await getToken(),
      sampleRate: 16_000,
      endUtteranceSilenceThreshold: 4000,
    })

    const texts = {}
    realtimeTranscriber.current.on('transcript', (transcript) => {
      let msg = ''
      texts[transcript.audio_start] = transcript.text
      const keys = Object.keys(texts)
      keys.sort((a, b) => a - b)
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`
          console.log(msg)
        }
      }
      setTranscript(msg)
      if (transcript.message_type === 'FinalTranscript') {
        endTranscription()
      }
    })

    realtimeTranscriber.current.on('error', (event) => {
      console.error(event)
      realtimeTranscriber.current.close()
      realtimeTranscriber.current = null
    })

    realtimeTranscriber.current.on('close', (code, reason) => {
      console.log(`Connection closed: ${code} ${reason}`)
      realtimeTranscriber.current = null
    })

    await realtimeTranscriber.current.connect()

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        recorder.current = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm;codecs=pcm',
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: async (blob) => {
            if (!realtimeTranscriber.current) return
            const buffer = await blob.arrayBuffer()
            realtimeTranscriber.current.sendAudio(buffer)
          },
        })
        recorder.current.startRecording()
      })
      .catch((err) => console.error(err))

    setIsLoading(false)
    setIsRecording(true)
  }

  const endTranscription = async (event) => {
    event?.preventDefault()
    setIsRecording(false)

    await realtimeTranscriber.current.close()
    realtimeTranscriber.current = null

    recorder.current.pauseRecording()
    recorder.current = null
  }

  const handleChangeRecording = () => {
    if (isRecording) endTranscription()
    else startTranscription()
  }

  return {
    startTranscription,
    endTranscription,
    handleChangeRecording,
    transcript,
    isRecording,
    isLoading,
  }
}
