export const featureRoutes = [
  '/dashboard',
  '/chat',
  '/writer',
  '/writer/reply',
  '/writer/modify',
  '/writer/check',
  '/advisor',
  '/translator',
  '/great-ideas',
  '/image',
  '/image/modify',
  '/image/gallery',
  '/account/your-story',
  '/account/chat-bot',
  '/account/redeem',
]
