import { Save } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import { useAccess } from 'components/hooks/access'
import { Modal, ViewContext, useLocation } from 'components/lib'
import { clamp, errorToast, queryToObj, successToast } from 'helpers'
import { CircleNotch } from 'phosphor-react'
import { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  modifyImageInitialState,
  selectModifyImageInputHistory,
  setModifyImageInputHistory,
} from 'store/toolInputHistoriesHolder/modify-image'
import TemplateDeleteAlertContent from 'toolComponents/delete-template-modal-content'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { Button, Input } from 'ui'
import { styles, types } from './models/imageOptions'
import InpaintLayout from './modify/Inpainting'
import ModifyLayout from './modify/Modify'
import ChangeBackgroundLayout from './modify/Background'
import classNames from 'classnames'
import { ContextModal } from 'components/document-context/ContextModal'
import { useDocumentContext } from 'components/hooks/context'
import { selectIsReady } from 'store/generations'

// const actions = [
//   {
//     value: 'Create similar',
//     label: <T keyName='eleo-select-option-create-similar'>Create similar</T>,
//   },
//   {
//     value: 'Face restoration',
//     label: <T keyName='eleo-select-option-face-restoration'>Face restoration</T>,
//   },
// ]

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOOL':
      return { ...state, tool: action.payload }
    case 'SET_PROMPT':
      return { ...state, prompt: action.payload }
    // case 'SET_KEYWORDS':
    //   return { ...state, keywords: action.payload }
    case 'SET_NEGATIVE_PROMPT':
      return { ...state, negativePrompt: action.payload }
    case 'SET_QUALITY':
      return { ...state, quality: action.payload }
    case 'SET_CREATIVITY':
      return { ...state, creativity: action.payload }
    case 'SET_STYLE':
      return { ...state, style: action.payload }
    case 'SET_TYPE':
      const data = { allowStyles: true }
      if (!state.style) data.style = styles[0].value

      switch (action.payload) {
        case 'Icon':
          data.type = 'Icon'
          data.creativity = 5
          data.quality = 8
          // data.chosenProportions = imageProportions[0].value
          // data.imageSize = resolutions['Square'][0]
          data.allowStyles = false
          data.style = null
          break
        case 'Logo':
          data.type = 'Logo'
          data.creativity = 2
          data.quality = 9
          // data.chosenProportions = imageProportions[0].value
          // data.imageSize = resolutions['Square'][0]
          data.allowStyles = false
          data.style = null
          break
        case 'Anything':
          data.type = 'Anything'
          data.creativity = 5
          data.quality = 5
          break
        case 'Portrait':
          data.type = 'Portrait'
          data.creativity = 4
          break
        case 'Character':
          data.type = 'Character'
          data.creativity = 7
          break
        default:
          break
      }
      return { ...state, ...data }
    case 'SET_SAMPLES':
      return { ...state, samples: Number(action.payload) }
    // case 'SET_PROPORTIONS':
    //   return { ...state, chosenProportions: action.payload }
    // case 'SET_SIZE':
    //   return { ...state, imageSize: action.payload }
    case 'SET_MORPH_STRENGTH':
      return { ...state, morphStrength: action.payload }
    case 'SET_TEMPLATE':
      return { ...state, template: action.payload }
    case 'SET_MASK':
      return { ...state, mask: action.payload }
    case 'SET_IS_BG_MODIFY':
      return { ...state, isBgModify: action.payload }
    case 'APPLY_TEMPLATE':
      const values = action.payload

      let templateData = {
        prompt: values.description,
        keywords: values.keywords,
        negativePrompt: values.avoid,
        quality: clamp(0, values.quality, 10),
        creativity: clamp(0, values.creativity, 10),
        style: styles.find((s) => values.style?.toLowerCase() === s.value.toLowerCase())?.value,
        type: types.find((t) => values.type?.toLowerCase() === t.value.toLowerCase())?.value,
        template: values.id,
        ...(values.samples && {
          samples: clamp(1, Number(values.samples), 4),
        }),
      }

      templateData = Object.fromEntries(
        Object.entries(templateData).filter(([key, value]) => Boolean(value))
      )

      return { ...state, ...templateData }
    case 'LOAD_DATA':
      const historyData = {
        ...action.payload,
        prompt: '',
        negativePrompt: '',
        template: '',
        morphStrength: 0.8,
        samples: 4,
      }
      return { ...state, ...historyData }

    case 'RESET':
      return modifyImageInitialState.imageFormValues
    default:
      return state
  }
}

function ImageModifyForm(
  {
    imageData,
    setImageData,
    baseImage,
    setBaseImage,
    setActiveImage,
    setIsLoading,
    isLoading,
    setHistory,
    setCurrentIndex,
    setAbortController,
    requestGeneration,
    getGeneration,
    wsMessage,
    setIsImageInQueue,
    queueSize,
  },
  ref
) {
  const dispatchAction = useDispatch()
  const imageFormData = useSelector(selectModifyImageInputHistory)
  const [state, dispatch] = useReducer(reducer, imageFormData)
  const documentContext = useDocumentContext('image-modify')
  const isReady = useSelector(selectIsReady)

  // Update store
  useEffect(() => {
    dispatchAction(setModifyImageInputHistory(state))
  }, [state, dispatchAction])

  const { t } = useTranslate()
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')
  const location = useLocation()

  const [loadState, setLoadState] = useState({ baseImage: false })
  const [imageSize, setImageSize] = useState()
  const modifyImageInputHistory = useSelector(selectModifyImageInputHistory)
  const [action, setAction] = useState(modifyImageInputHistory.action)
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })

  const toolLayouts = {
    modify: ModifyLayout,
    inpaint: InpaintLayout,
    outpaint: '',
    background: ChangeBackgroundLayout,
  }
  const FormLayout = toolLayouts[state.tool]

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'APPLY_TEMPLATE',
      payload: data,
    })
  }, [location])

  // Reset mask when changing the base image
  useEffect(() => {
    if (!loadState.baseImage) return setLoadState((prev) => ({ ...prev, baseImage: true }))
    dispatch({
      type: 'SET_MASK',
      payload: null,
    })
  }, [baseImage])

  /* Template Section */
  const [newTemplateName, setNewTemplateName] = useState('')
  const [templates, setTemplates] = useState([])

  // useEffect(() => {
  //   fetchTemplates()
  // }, [])

  // Load template
  useEffect(() => {
    const templateData = templates.find((item) => item.id === state.template)
    if (!templateData) return

    dispatch({
      type: 'APPLY_TEMPLATE',
      payload: templateData,
    })
  }, [state.template, templates])

  // async function fetchTemplates() {
  //   try {
  //     const url = '/api/image/create/templates'
  //     const res = await axios.get(url)
  //     setTemplates(res.data.data)
  //   } catch (err) {
  //     context.handleError(err)
  //   }
  // }

  const templateSelection = templates.map((item) => {
    return { value: item.id, label: item.templateName }
  })
  /* Template Section */

  useImperativeHandle(ref, () => ({
    resubmit() {
      handleSubmit()
    },
  }))

  // Executed when initialising a new instance from redirect
  useEffect(() => {
    if (!location.state) return

    setHistory([])
    setActiveImage(null)
    setBaseImage(location.state.baseImage)
    setCurrentIndex(-1)

    const locationImageData = location.state.imageData
    if (!locationImageData) return

    setImageData(locationImageData)

    dispatch({
      type: 'LOAD_DATA',
      payload: locationImageData,
    })
  }, [location.state])

  // async function handleSubmit(event) {
  //   if (event) event.preventDefault()

  //   if (!hasAccess('Standard')) return showUpgradePlanModal('Standard')

  //   if (!baseImage)
  //     return errorToast(t('eleo-error-toast-upload-image', 'Please upload your image'))

  //   setIsLoading(true)
  //   const controller = new AbortController()
  //   setAbortController(controller)

  //   const url = '/api/ai/image/morph'

  //   const newPrompt = state.prompt
  //     ? state.prompt.trim() + (imageData ? ', ' + imageData.prompt : '')
  //     : imageData?.prompt ?? ''
  //   const newNegativePrompt = state.negativePrompt
  //     ? state.negativePrompt.trim() + (imageData ? ', ' + imageData.negativePrompt : '')
  //     : imageData?.negativePrompt ?? ''

  //   const data = {
  //     ...imageData, // Pass the parameters of the initial image
  //     initImage: baseImage, // and it's url
  //     prompt: newPrompt,
  //     negativePrompt: newNegativePrompt,
  //     style: state.style,
  //     type: state.type,
  //     creativity: state.creativity,
  //     quality: state.quality,
  //     samples: state.samples,
  //     strength: state.morphStrength,
  //     // keywords: keywordsValue ? keywordsValue.split(/, |,/) : '',
  //   }

  //   try {
  //     const res = await axios.post(url, data, { signal: controller.signal })

  //     const images = res.data.map((url) => IMAGE_PROXY_URL + url)

  //     // Save form inputs
  //     setHistory((prev) => [...prev, { images: images, data: data }])
  //     context.setImagesLeft((prev) => prev - Number(state.samples))

  //     setIsLoading(false)
  //   } catch (err) {
  //     if (err.code === 'ERR_CANCELED') {
  //       console.log('Abort')
  //       return
  //     }

  //     if (!err.response.data.message)
  //       errorToast(t('eleo-generate-image-error', 'Failed to generate your image.'))
  //     context.handleError(err)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  async function handleSubmit(e) {
    if (e) e.preventDefault()

    if (!allowStandard) return showUpgradePlanModal()

    if (!baseImage)
      return errorToast(t('eleo-error-toast-upload-image', 'Please upload your image'))
    if (state.tool === 'inpaint' && !state.mask)
      return errorToast(
        t('eleo-error-select-mask', 'Please click your image to select what you want to change')
      )

    const newPrompt = [state.prompt, imageData?.prompt].filter(Boolean).join(', ')
    const newNegativePrompt = [state.negativePrompt, imageData?.negativePrompt]
      .filter(Boolean)
      .join(', ')

    setIsImageInQueue(true)
    setIsLoading(true)
    requestGeneration({
      ...state,
      image: baseImage,
      imageSize: imageSize,
      prompt: newPrompt,
      negativePrompt: newNegativePrompt,
      tool: state.tool,
    })
  }

  async function handleFileUpload(e) {
    try {
      setIsLoading(true)

      const url = '/api/utility/upload'
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('dir', 'user_images/')

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }

      const res = await axios.post(url, data, config)
      setBaseImage(res.data.items[0])
      setImageData({})

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  async function handleSaveTemplate() {
    try {
      const url = '/api/image/save-template'
      const data = {
        image_templates: {
          templateName: newTemplateName,
          description: state.prompt,
          avoid: state.negativePrompt,
          type: state.type,
          style: state.style,
          samples: state.samples,
          quality: state.quality,
          creativity: state.creativity,
          action: action,
          strength: state.morphStrength,
        },
        tool: 'modify',
      }

      await axios.post(url, data)
      successToast(t('eleo-save-template-success', 'Template saved successfully!'))
      setNewTemplateName('')
      // fetchTemplates()
    } catch (err) {
      context.handleError(err)
    }
  }

  // function showDeleteConfirmationModal(templateId) {
  //   context.modal.show({
  //     children: (
  //       <TemplateDeleteAlertContent
  //         reloadTemplatesData={fetchTemplates}
  //         deleteTemplateEndpoint={() => handleDeleteTemplate(templateId)}
  //         hideGlobalModal={() => context.modal.hide(true)}
  //       />
  //     ),
  //   })
  // }

  async function handleDeleteTemplate(templateId) {
    return fetch(`${axios.defaults.baseURL}/api/image/delete-template`, {
      method: 'POST',
      body: JSON.stringify({ templateId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })
  }

  function showUpgradePlanModal(availableFrom = 'Standard') {
    context.modal.show({
      children: <UpgradePlan availableFrom={availableFrom} />,
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  function handleShowTemplateModal() {
    if (!allowStandard) {
      showUpgradePlanModal()
      return
    }

    setModalIsVisible({ visible: true })
  }

  return (
    <>
      {/* label */}
      <div className='flex justify-between border-b px-4 py-3 text-lg'>
        <div>
          <T keyName='eleo-tool-modify-image-header'>MODIFY IMAGE</T>
        </div>
      </div>

      {/* Form contents */}

      <FormLayout
        state={state}
        dispatch={dispatch}
        baseImage={baseImage}
        imageData={imageData}
        handleFileUpload={handleFileUpload}
        showUpgradePlanModal={showUpgradePlanModal}
        templateSelection={templateSelection}
        // showDeleteConfirmationModal={showDeleteConfirmationModal}
        setBaseImage={setBaseImage}
        imageSize={imageSize}
        setImageSize={setImageSize}
        isReady={isReady}
      />

      {/* Buttons */}
      <div className='bg-brand-grey-bg sticky bottom-0 z-10 flex gap-2 p-[16px]'>
        {/* <Button
          color='gray'
          className='h-[45px] px-[14px]'
          onClick={handleShowTemplateModal}
          data-tooltip-id='images-modifyImage-saveTemplate'
        >
          <div
            className={classNames('flex items-center gap-[6px]', {
              'opacity-50': !allowStandard,
            })}
          >
            <Save className='text-brand-secondary' />
          </div>
        </Button> */}
        <Button
          color='gray'
          onClick={() =>
            dispatch({
              type: 'RESET',
            })
          }
          data-tooltip-id='images-modifyImage-clear'
        >
          <p className='text-[15px] font-medium uppercase leading-none'>
            <T keyName='eleo-history-action-clear'>clear</T>
          </p>
        </Button>

        <Button
          color='dark'
          className='h-[45px] flex-1'
          disabled={isLoading || !isReady}
          data-tooltip-id='images-modifyImage-generate'
          onClick={handleSubmit}
        >
          {isLoading ? (
            queueSize ? (
              t('eleo-image-queue-remaining', 'Queue remaining:') + ' ' + queueSize
            ) : (
              <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
            )
          ) : (
            <p className='text-[15px] font-medium uppercase leading-none'>
              <T keyName='eleo-tool-create-image-generate-action'>generate image</T>
            </p>
          )}
        </Button>
      </div>

      {modalIsVisible.visible && (
        <Modal
          setHideModal={setModalIsVisible}
          text={
            <p className='text-brand-body text-[19px] font-bold'>
              <T keyName='eleo-save-template-modal-title'>Save Template</T>
            </p>
          }
        >
          <div className='flex  w-full flex-col gap-[10px]'>
            <Input
              placeholder={t('eleo-enter-template-name', 'Enter template name')}
              className='border-brand-grey border'
              value={newTemplateName}
              onChange={setNewTemplateName}
            />
            <Button
              color='green'
              className='!float-none h-[45px] !w-full'
              onClick={async () => {
                if (!newTemplateName) return
                setIsLoading(true)
                try {
                  await handleSaveTemplate()
                  setModalIsVisible({ visible: false })
                  setIsLoading(false)
                } catch (err) {
                  setIsLoading(false)
                }
              }}
              disabled={isLoading}
            >
              <div className='flex items-center gap-[5px]'>
                {isLoading ? (
                  <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
                ) : (
                  <Save className='text-white' />
                )}
                <p className='!mb-0'>
                  <T keyName='eleo-template-update'>save</T>
                </p>
              </div>
            </Button>
          </div>
        </Modal>
      )}
      <ContextModal documentContext={documentContext} />
    </>
  )
}

export default forwardRef(ImageModifyForm)
