import { T } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Modal } from 'toolComponents/generic/modal'

export const PwaInstall = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const handler = (e) => {
      if (params.get('pwa')) {
        e.preventDefault()
        setPromptInstall(e)
        setIsModalVisible(true)
        setParams()
      }
    }

    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('beforeinstallprompt', handler)
  }, [params, setParams])

  return (
    <Modal
      title={<T keyName='eleo-install-pwa-modal-title'>Do you want to install the Eleo.ai app?</T>}
      isVisible={isModalVisible}
      hideModal={setIsModalVisible}
      callback={() => promptInstall.prompt()}
      acceptLabel={<T keyName='eleo-install'>Install</T>}
      isValid
    />
  )
}
