import { useState, useEffect, useReducer, useCallback } from 'react'
import axios from 'axios'
import { Animate } from 'components/lib'
import TranslatorPanel from 'toolComponents/translator/TranslatorPanel'
import TranslatorHistory from 'toolComponents/translator/TranslatorHistory'
import {
  setTranslatorGeneratedResultHistory,
  setTranslatorInputHistory,
  translatorGeneratedResultHistory,
  translatorInputHistory,
  translatorInitialState,
} from 'store/toolInputHistoriesHolder/translator'
import { useDispatch, useSelector } from 'react-redux'
import { HelpTooltips } from 'components/help/helpTooltips'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { languages, queryToObj } from 'helpers'
import Tour from 'components/tour'
import { T } from '@tolgee/react'

// Tour steps
const steps = [
  {
    target: '#input',
    content: (
      <T keyName='eleo-tour-translator-1'>
        Enter the text for translation. Paste or type the text you want to translate.
      </T>
    ),
    placementBeacon: 'top',
  },
  {
    target: '#language',
    content: (
      <T keyName='eleo-tour-translator-2'>
        Choose the language. Easily translate the same text into multiple languages.
      </T>
    ),
  },
  {
    target: '#native',
    content: (
      <T keyName='eleo-tour-translator-3'>
        Select the Native Speaker option. Instead of word-for-word translation, the text is
        translated as if a native speaker did it.
      </T>
    ),
  },
  {
    target: '#story',
    content: (
      <T keyName='eleo-tour-translator-4'>
        Add Your Story. Your story will not be translated, but Eleo will have additional information
        to accurately translate your text.
      </T>
    ),
  },
  {
    target: '#context',
    content: (
      <T keyName='eleo-tour-translator-5'>
        Use the context menu. Highlight a translation fragment to change, shorten, or lengthen it.
      </T>
    ),
    placementBeacon: 'top',
  },
  {
    target: '#history',
    content: (
      <T keyName='eleo-tour-translator-6'>
        Access translation history. Check your previous translations
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INPUT':
      return { ...state, inputValue: action.payload }
    case 'SET_INPUT_LANGUAGE':
      return { ...state, inputLanguage: action.payload }
    case 'SET_OUTPUT_LANGUAGE':
      return { ...state, outputLanguage: action.payload }
    case 'SET_IS_NATIVE':
      return { ...state, isNative: action.payload }
    case 'SET_IS_AUDIO':
      return { ...state, isAudio: action.payload }
    case 'SET_IS_GURU_ENABLED':
      return { ...state, isGuruModeEnabled: action.payload }
    case 'SET_YOUR_STORY':
      return { ...state, yourStory: action.payload }
    case 'SET_MODEL':
      return { ...state, model: { ...action.payload, description: undefined } }
    case 'LOAD_HISTORY':
      let data = {
        inputValue: action.payload.input,
        inputLanguage: languages.find(
          (l) => action.payload.inputLanguage?.toLowerCase() === l.value.toLowerCase()
        )?.value,
        outputLanguage: languages.find(
          (l) => action.payload.outputLanguage?.toLowerCase() === l.value.toLowerCase()
        )?.value,
        isNative:
          action.payload.isWriteAsNative ??
          (action.payload.native ? Boolean(Number(action.payload.native)) : true),
        isGuruModeEnabled: action.payload.isGuruModeEnabled,
        yourStory: action.payload.story
          ? { value: action.payload.story?.id, label: action.payload.story?.templateName }
          : null,
      }

      data = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => Boolean(value) || key === 'isNative')
      )

      return { ...state, ...data }
    case 'RESET':
      return translatorInitialState.history
    default:
      return state
  }
}

export function Translator(props) {
  const dispatchAction = useDispatch()
  const context = useContext(ViewContext)
  const location = useLocation()

  const [showHistory, setShowHistory] = useState(false)
  const [history, setHistory] = useState([])
  const [historyMeta, setHistoryMeta] = useState({ limit: null, page: 1, total: null })

  const translatorInputHistoryValue = useSelector(translatorInputHistory)
  const generatedTextHistoryValue = useSelector(translatorGeneratedResultHistory)
  const [state, dispatch] = useReducer(reducer, translatorInputHistoryValue)
  const [outputValue, setOutputValue] = useState(generatedTextHistoryValue)

  useEffect(() => {
    dispatchAction(setTranslatorInputHistory(state))
    dispatchAction(setTranslatorGeneratedResultHistory(outputValue))
  }, [state, outputValue, dispatchAction])

  const [isRunTour, setIsRunTour] = useState(false)
  useEffect(() => {
    const tourCompleted = localStorage.getItem('tour-translator')
    if (tourCompleted) return

    const timeout = setTimeout(() => {
      setIsRunTour(true)
      localStorage.setItem('tour-translator', 'true')
    }, 500)
    return () => clearTimeout(timeout)
  }, [])

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    if (data.question) {
      dispatch({
        type: 'SET_INPUT',
        payload: data.question,
      })
    }
    if (data.response) setOutputValue(data.response)

    dispatch({
      type: 'LOAD_HISTORY',
      payload: data,
    })
  }, [location])

  const fetchHistory = useCallback(async () => {
    try {
      const { data: response } = await axios.get('api/translator/history?limit=10')
      const historyResponse = response.data
      setHistory(historyResponse.data.history)
      setHistoryMeta({
        limit: historyResponse.limit,
        page: historyResponse.page,
        total: historyResponse.total,
      })
    } catch (err) {
      context.handleError(err)
    }
  }, [context])

  useEffect(() => {
    fetchHistory()
  }, [])

  return (
    <Animate type='pop'>
      <div className='flex h-full'>
        {showHistory && (
          <TranslatorHistory
            history={history}
            dispatch={dispatch}
            setOutputValue={setOutputValue}
            setShowHistory={setShowHistory}
            fetchHistory={fetchHistory}
            setHistory={setHistory}
            historyMeta={historyMeta}
            setHistoryMeta={setHistoryMeta}
          />
        )}
        <TranslatorPanel
          setShowHistory={setShowHistory}
          showHistory={showHistory}
          fetchHistory={fetchHistory}
          state={state}
          dispatch={dispatch}
          outputValue={outputValue}
          setOutputValue={setOutputValue}
        />
        <HelpTooltips tool='translator' />
      </div>
      <Tour steps={steps} run={isRunTour} />
    </Animate>
  )
}
