import { Check, KeyboardArrowRight, MoreVert } from '@mui/icons-material'
import { CircularProgress, Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { successToast } from 'helpers'
import { useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectChatBotInputValues,
  SelectChatBotListData,
  SelectChatBotSettings,
  setClearFields,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import { ListPosition, Microbutton } from 'ui'
import FallbackImage from '../../../images/fallback-bot.png'
import { ViewContext } from 'components/lib'
import DuplicateModalView from 'toolComponents/chat-bot/library/DuplicateModalView'

const ChatActions = ({
  handleGenerateChatBot,
  isEditMode,
  loadingState,
  handleResetToServerState,
  isUpdatePending,
  isDraftSaved,
  isDraft,
  setModalData,
}) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const context = useContext(ViewContext)
  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const chatBotSettings = useSelector(SelectChatBotSettings)
  const mainPopoverRef = useRef()
  const chatBotList = useSelector(SelectChatBotListData)

  const [mainAnchorEl, setMainAnchorEl] = useState(null)
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null)

  const handleOpenMainPopover = (event) => {
    setMainAnchorEl(event.currentTarget)
  }
  const handleCloseMainPopover = () => {
    setMainAnchorEl(null)
    setSecondaryAnchorEl(null)
  }
  const handleOpenSecondaryPopover = (event) => {
    setSecondaryAnchorEl(event.currentTarget)
  }
  const handleCloseSecondaryPopover = (e) => {
    setSecondaryAnchorEl(null)

    // Also close the main popover if clicked outside of it
    if (!mainPopoverRef.current) return
    const { top, left, bottom, right } = mainPopoverRef.current.getBoundingClientRect()
    if (e) {
      const { clientX, clientY } = e
      if (!(clientX >= left && clientX <= right && clientY >= top && clientY <= bottom))
        setMainAnchorEl(null)
    }
  }

  const isMainPopoverOpen = Boolean(mainAnchorEl)
  const isSecondaryPopoverOpen = Boolean(secondaryAnchorEl)
  const popoverId = isMainPopoverOpen ? 'templates' : undefined

  function showDuplicateDraftModal(originalChatBotId) {
    handleCloseMainPopover()
    context.modal.show({
      children: (
        <div>
          <DuplicateModalView
            originalChatBotId={originalChatBotId}
            handleDuplicateChatBot={(botId, newName) => {
              context.modal.hide()
              handleGenerateChatBot(true, true, newName)
            }}
          />
        </div>
      ),
    })
  }

  return (
    <>
      <Microbutton
        variant='transparent-light'
        type='large-font'
        className='h-[40px] w-[40px] min-w-0 truncate uppercase'
        onClick={handleOpenMainPopover}
        data-tooltip-id='chatBotPanel-clear'
        icon={<MoreVert fontSize='inherit' />}
      />

      <Popover
        id={popoverId}
        open={isMainPopoverOpen}
        anchorEl={mainAnchorEl}
        onClose={handleCloseMainPopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div
          ref={mainPopoverRef}
          className='bg-brand-accent-flat border-brand-form-accent space-y-[6px] rounded-[4px] border p-2 pb-[10px]'
        >
          <ListPosition
            text={t('eleo-chatbot-reset', 'Reset changes')}
            disabled={!isUpdatePending || isDraftSaved}
            onClick={() => {
              successToast(
                <div>
                  <div className='mb-2 text-[13px] font-semibold'>
                    <T keyName='eleo-chatbot-changes-reset'>Changes have been reset</T>
                  </div>
                  <div
                    className='-m-2 p-2 text-[12px] font-light underline'
                    onClick={() => {
                      dispatch(setSelectedChatBotValues(chatBotInputValues))
                      dispatch(setSelectedChatBotSettings(chatBotSettings))
                    }}
                  >
                    <T keyName='eleo-undo'>undo</T>
                  </div>
                </div>
              )
              isEditMode ? handleResetToServerState() : dispatch(setClearFields())
            }}
          />
          <ListPosition
            text={t('eleo-chatbot-duplicate-as-draft', 'Duplicate as draft')}
            onClick={() =>
              isDraft ? showDuplicateDraftModal() : handleGenerateChatBot(true, true)
            }
          />
          <ListPosition
            text={t('eleo-chatbot-convert-to-draft', 'Convert to draft')}
            onClick={() => handleGenerateChatBot(true)}
            disabled={isDraft}
          />

          {!isDraft &&
            Boolean(
              chatBotList.filter(
                (bot) => bot.type !== 'draft' && bot.id !== chatBotInputValues.selectedChatBot
              )?.length
            ) && (
              <>
                <div className='border-brand-form-accent-light border-b'></div>
                <ListPosition
                  icon={<KeyboardArrowRight fontSize='inherit' />}
                  text={t('eleo-chatbot-overwrite with', 'Overwrite with')}
                  onClick={handleOpenSecondaryPopover}
                />
                <Popover
                  id='secondary-popover'
                  open={isSecondaryPopoverOpen}
                  anchorEl={secondaryAnchorEl}
                  onClose={handleCloseSecondaryPopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className='bg-brand-accent-flat border-brand-form-accent roudned-[4px] max-h-[200px] min-w-32 rounded-[4px] border p-2 pb-[10px]'>
                    {chatBotList
                      .filter(
                        (bot) =>
                          bot.type !== 'draft' && bot.id !== chatBotInputValues.selectedChatBot
                      )
                      .map((bot) => (
                        <ListPosition
                          key={bot.id}
                          text={bot.name}
                          iconPlacement='left'
                          icon={
                            <img
                              src={bot.avatar?.length ? bot.avatar : FallbackImage}
                              alt=''
                              className='size-4'
                            />
                          }
                          // icon={<div>test</div>}
                          onClick={() => {
                            handleCloseMainPopover()
                            handleCloseSecondaryPopover()
                            setModalData({
                              isVisible: true,
                              title: (
                                <T
                                  keyName='eleo-chatbot-modal-override-title'
                                  defaultValue='Overwrite {name1} with {name2}'
                                  params={{ name1: chatBotInputValues.name, name2: bot.name }}
                                />
                              ),
                              subtitle: (
                                <div>
                                  <div>
                                    <T keyName='eleo-chatbot-override-desc-1'>
                                      All of the chatbot settings, including publishing settings,
                                      will be overriden by chatbot My chatbot.
                                    </T>
                                  </div>
                                  <div>
                                    <T keyName='eleo-chatbot-override-desc-2'>
                                      You will still be able to reset changes to your form until you
                                      save or publish them.
                                    </T>
                                  </div>
                                </div>
                              ),
                              acceptLabel: <T keyName='eleo-override'>Override</T>,
                              type: 'override',
                              context: { id: bot.id },
                            })
                          }}
                        />
                      ))}
                  </div>
                </Popover>
              </>
            )}
        </div>
      </Popover>

      {(!isEditMode || isDraft) && (
        <Microbutton
          variant={isDraft ? 'solid' : 'transparent-light'}
          type='large-font'
          className={classNames(
            'h-[40px] min-w-[100px] flex-1 uppercase',
            (!isUpdatePending || loadingState.publish) && 'opacity-70',
            isDraftSaved &&
              '!bg-brand-green-secondary hover:!bg-brand-green-secondary/80 !text-white'
          )}
          onClick={() => handleGenerateChatBot(true)}
          data-tooltip-id='chatBotPanel-clear'
          disabled={!isUpdatePending || loadingState.publish || isDraftSaved}
          icon={isDraftSaved && <Check fontSize='inherit' />}
          iconSize={18}
        >
          <div className='flex items-center gap-[6px] truncate'>
            <div className='truncate'>
              {isDraftSaved ? (
                <T keyName='eleo-chatbot-draft-saved'>Draft up to date</T>
              ) : isDraft ? (
                <T keyName='eleo-chatbot-update-draft'>Update draft</T>
              ) : (
                <T keyName='eleo-chatbot-save-draft'>Save as draft</T>
              )}
            </div>
            {loadingState.draft && (
              <CircularProgress size={14} sx={{ animationDuration: '1000ms' }} color='inherit' />
            )}
          </div>
        </Microbutton>
      )}

      <Microbutton
        variant='solid'
        type='large-font'
        className={classNames(
          'h-[40px] min-w-[80px] flex-1 uppercase',
          loadingState.draft && 'opacity-70',
          isEditMode && !isUpdatePending && '!bg-brand-green-secondary'
        )}
        onClick={() => handleGenerateChatBot()}
        data-tooltip-id='chatBotPanel-clear'
        disabled={loadingState.draft || (isEditMode && !isUpdatePending)}
        icon={isEditMode && !isUpdatePending && <Check fontSize='inherit' />}
        iconSize={18}
      >
        <div className='flex items-center gap-[6px] truncate'>
          <div className='truncate'>
            {isEditMode ? (
              isUpdatePending ? (
                <T keyName='eleo-chatbot-publish-changes'>Publish changes</T>
              ) : (
                <T keyName='eleo-chatbot-published'>Published</T>
              )
            ) : (
              <T keyName='eleo-chatbot-publish'>Publish</T>
            )}
          </div>
          {loadingState.publish && (
            <CircularProgress size={14} sx={{ animationDuration: '1000ms' }} color='inherit' />
          )}
        </div>
      </Microbutton>
    </>
  )
}

export default ChatActions
