import { Android, Apple, Download } from '@mui/icons-material'
import { T } from '@tolgee/react'
import { useEffect, useState } from 'react'
import { Button } from 'ui'

export function PwaInstall() {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault()
      setPromptInstall(e)
      setSupportsPWA(true)
    }

    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('beforeinstallprompt', handler)
  }, [])

  const handleInstallClick = async () => {
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
    const { outcome } = await promptInstall.userChoice
    setPromptInstall(null)
    console.log(`User response to the install prompt: ${outcome}`)
  }

  if (!supportsPWA) {
    return null
  }

  return (
    <div className='flex flex-col gap-2 border-y py-4 sm:border-b-0'>
      <Button
        variant='small'
        color='violet'
        onClick={handleInstallClick}
        className='hidden max-w-[260px] justify-between sm:flex'
      >
        <T keyName='eleo-download-pwa-desktop'>Download desktop app</T> <Download />
      </Button>
      <Button
        variant='small'
        color='violet'
        onClick={handleInstallClick}
        className='flex max-w-[260px] justify-between sm:hidden'
      >
        <T keyName='eleo-download-pwa-desktop'>Download For Android</T> <Android />
      </Button>
      <Button
        variant='small'
        color='violet'
        onClick={handleInstallClick}
        className='flex max-w-[260px] justify-between sm:hidden'
      >
        <T keyName='eleo-download-pwa-desktop'>Download For iOS</T> <Apple />
      </Button>
    </div>
  )
}
